import React from "react"
import Layout from "../layouts/ja"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import ListMainLayout from '../layouts/listmain'

const IndexPage = (props) => (
  <Layout location={props.location}>
    <SEO title="ホーム" description="Channel.241のトップページ。さまざまなカテゴリのお気に入りの記事を見つけることができます。" lang="ja" />
    <ListMainLayout data={props.data} langKey="ja"/>
  </Layout>
)

export const query = graphql`
  query {
    allMarkdownRemark(filter: { fields: { langKey: { eq: "ja" } } }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            tags
            author
            price
          }
          fields {
            slug
            langKey
          }
          excerpt
        }
      }
    }
  }
`

export default IndexPage
